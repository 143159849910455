import React from 'react'
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material'

const Om721Logo = (props: SvgIconProps) => {
  // @ts-ignore
  const { color, fill, stroke, sx, width, height, ...otherProps } = props // eslint-disable-line
  const theme = useTheme()

  let selectedColor = color

  // @ts-ignore
  if (color && theme.palette[color]) {
    // @ts-ignore
    selectedColor = theme.palette[color].main
  }

  const fillStyle = fill || selectedColor || undefined
  

  const styles = {
    width: 'inherit',
    height: 'inherit',
    fill: 'inherit',
    ...sx,
  }

  const cleanedPaths = () => (<svg width={width || 239} height={height || 33} viewBox="0 0 239 33" fill="none">
<path d="M12.5529 13.3379C8.14878 13.3379 4.28763 11.0161 2.12578 7.53045C1.75027 6.92533 0.828503 7.07122 0.656085 7.76172C0.417354 8.71748 0.290527 9.71717 0.290527 10.7475C0.293014 17.5547 5.78136 23.0248 12.5885 23.0049C19.3443 22.9858 24.8144 17.5033 24.8144 10.7426C24.8144 9.71385 24.6876 8.71582 24.4489 7.76089C24.2764 7.06957 23.3547 6.9245 22.9792 7.52962C20.8181 11.0161 16.957 13.3379 12.5529 13.3379Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M48.958 9.33461C50.513 10.8673 51.2897 12.7581 51.2897 15.0061C51.2897 17.2542 50.5122 19.145 48.958 20.6777C47.4037 22.2104 45.4947 22.9771 43.2317 22.9771C40.9836 22.9771 39.0788 22.2104 37.5162 20.6777C35.9537 19.145 35.1729 17.2542 35.1729 15.0061C35.1729 12.7581 35.9537 10.8673 37.5162 9.33461C39.0779 7.80192 40.9828 7.03516 43.2317 7.03516C45.4947 7.03516 47.4037 7.80192 48.958 9.33461ZM46.8235 18.7504C47.7726 17.7433 48.2467 16.4949 48.2467 15.0061C48.2467 13.5174 47.7718 12.269 46.8235 11.2619C45.8743 10.2547 44.6774 9.75073 43.2325 9.75073C41.7877 9.75073 40.5941 10.2547 39.6524 11.2619C38.7107 12.269 38.2399 13.5174 38.2399 15.0061C38.2399 16.4949 38.7107 17.7433 39.6524 18.7504C40.5941 19.7576 41.7877 20.2616 43.2325 20.2616C44.6774 20.2616 45.8735 19.7576 46.8235 18.7504Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M58.6469 22.6705H55.8443C55.5525 22.6705 55.3552 22.5171 55.2533 22.2104L51.9027 12.7946C51.8298 12.6197 51.8373 12.463 51.9243 12.3238C52.0122 12.1854 52.1431 12.1157 52.318 12.1157H54.4417C54.7045 12.1157 54.8869 12.2616 54.9888 12.5534L57.3753 19.8016L59.5654 12.5534C59.6383 12.2616 59.8207 12.1157 60.1125 12.1157H62.1268C62.3017 12.1157 62.4335 12.1854 62.5205 12.3238C62.6084 12.463 62.6225 12.6197 62.5644 12.7946L59.2139 22.2104C59.1144 22.5171 58.9246 22.6705 58.6469 22.6705Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M68.6764 11.8091C70.2671 11.8091 71.5627 12.3272 72.5632 13.3642C73.5629 14.4011 74.0636 15.6412 74.0636 17.0869V17.6124C74.0636 17.934 73.8953 18.094 73.5596 18.094H66.0271C66.1001 18.8243 66.3993 19.4187 66.9248 19.8787C67.4504 20.3388 68.0704 20.5684 68.7858 20.5684C69.778 20.5684 70.6036 20.1962 71.2602 19.4518C71.45 19.233 71.6398 19.1965 71.8296 19.3424L73.209 20.3056C73.4568 20.4805 73.4858 20.6927 73.2968 20.9406C72.2308 22.2984 70.7056 22.9773 68.7203 22.9773C67.0848 22.9773 65.742 22.4484 64.6909 21.3899C63.6398 20.3313 63.1143 19.0141 63.1143 17.4375C63.1143 15.8609 63.6398 14.5288 64.6909 13.4412C65.742 12.3529 67.0699 11.8091 68.6764 11.8091ZM71.2817 16.2977C71.1499 15.5973 70.844 15.0535 70.3616 14.6664C69.88 14.2801 69.3246 14.0862 68.6971 14.0862C68.0547 14.0862 67.496 14.2868 67.0218 14.688C66.5477 15.09 66.2368 15.6263 66.0909 16.2977H71.2817Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M80.5223 11.9839H80.829C81.1647 11.9839 81.333 12.1663 81.333 12.531V14.4143C81.333 14.6041 81.2742 14.7426 81.1581 14.8304C81.0412 14.9183 80.9244 14.9614 80.8075 14.9614H80.2819C78.8073 14.9614 78.0703 15.8086 78.0703 17.5012V22.2527C78.0703 22.3695 78.0231 22.4682 77.9278 22.5486C77.8324 22.629 77.7272 22.6688 77.6103 22.6688H75.683C75.5662 22.6688 75.4642 22.6249 75.3763 22.5378C75.2885 22.4508 75.2454 22.348 75.2454 22.2311V12.5741C75.2454 12.4431 75.2893 12.3329 75.3763 12.2458C75.4634 12.1588 75.5662 12.1149 75.683 12.1149H77.5009C77.6178 12.1149 77.7197 12.1588 77.8076 12.2458C77.8954 12.3329 77.9386 12.4282 77.9386 12.5302V13.5373C78.5362 12.5028 79.3975 11.9839 80.5223 11.9839Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M85.2299 16.9552V22.2546C85.2299 22.3715 85.1827 22.4701 85.0873 22.5505C84.992 22.6309 84.8867 22.6707 84.7699 22.6707H82.8434C82.7266 22.6707 82.6246 22.6268 82.5367 22.5397C82.4489 22.4527 82.4058 22.3499 82.4058 22.233V12.576C82.4058 12.445 82.4497 12.3348 82.5367 12.2477C82.6238 12.1607 82.7266 12.1168 82.8434 12.1168H84.7707C84.8876 12.1168 84.9929 12.1607 85.0882 12.2477C85.1827 12.3356 85.2308 12.4301 85.2308 12.5321V13.3643C85.5516 12.9117 86.0083 12.5395 86.5993 12.2477C87.1904 11.956 87.8286 11.8101 88.515 11.8101C89.2884 11.8101 89.9673 11.9783 90.5517 12.314C91.1352 12.6498 91.5812 13.1024 91.8871 13.6718C92.8072 12.4309 94.0547 11.8109 95.6313 11.8109C97.0911 11.8109 98.2076 12.2452 98.9819 13.114C99.7553 13.9827 100.142 15.0379 100.142 16.278V22.2562C100.142 22.3731 100.095 22.4718 99.9998 22.5522C99.9045 22.6326 99.7992 22.6724 99.6823 22.6724H97.7774C97.6606 22.6724 97.5545 22.6326 97.46 22.5522C97.3646 22.4718 97.3174 22.3739 97.3174 22.2562V16.9569C97.3174 16.2125 97.1234 15.6032 96.7371 15.1283C96.35 14.6541 95.7922 14.4162 95.0619 14.4162C94.3175 14.4162 93.7372 14.6533 93.3211 15.1283C92.905 15.6024 92.6969 16.2125 92.6969 16.9569V22.2562C92.6969 22.3731 92.6497 22.4718 92.5544 22.5522C92.459 22.6326 92.3537 22.6724 92.2369 22.6724H90.332C90.2151 22.6724 90.109 22.6326 90.0145 22.5522C89.9192 22.4718 89.8719 22.3739 89.8719 22.2562V16.9569C89.8719 16.2125 89.678 15.6032 89.2917 15.1283C88.9046 14.6541 88.3467 14.4162 87.6164 14.4162C86.872 14.4162 86.2876 14.6574 85.8649 15.139C85.4413 15.619 85.2299 16.2258 85.2299 16.9552Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M101.367 17.4151C101.367 15.8385 101.918 14.5106 103.02 13.4296C104.122 12.3495 105.462 11.8091 107.038 11.8091C108.615 11.8091 109.953 12.3495 111.056 13.4296C112.158 14.5106 112.709 15.8385 112.709 17.4151C112.709 18.9777 112.158 20.2948 111.056 21.3675C109.953 22.4401 108.614 22.9773 107.038 22.9773C105.462 22.9773 104.122 22.4409 103.02 21.3675C101.919 20.294 101.367 18.9768 101.367 17.4151ZM105.025 15.2906C104.484 15.8601 104.215 16.5605 104.215 17.3928C104.215 18.225 104.485 18.9254 105.025 19.4949C105.564 20.0644 106.237 20.3487 107.039 20.3487C107.841 20.3487 108.514 20.0644 109.053 19.4949C109.593 18.9254 109.863 18.225 109.863 17.3928C109.863 16.5605 109.593 15.8601 109.053 15.2906C108.513 14.7211 107.841 14.4368 107.039 14.4368C106.237 14.4368 105.564 14.7211 105.025 15.2906Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M113.499 17.4151C113.499 15.8385 114.049 14.5106 115.152 13.4296C116.253 12.3495 117.593 11.8091 119.169 11.8091C120.746 11.8091 122.085 12.3495 123.187 13.4296C124.289 14.5106 124.84 15.8385 124.84 17.4151C124.84 18.9777 124.289 20.2948 123.187 21.3675C122.085 22.4401 120.745 22.9773 119.169 22.9773C117.594 22.9773 116.253 22.4409 115.152 21.3675C114.05 20.294 113.499 18.9768 113.499 17.4151ZM117.156 15.2906C116.616 15.8601 116.346 16.5605 116.346 17.3928C116.346 18.225 116.616 18.9254 117.156 19.4949C117.696 20.0644 118.368 20.3487 119.17 20.3487C119.973 20.3487 120.645 20.0644 121.185 19.4949C121.724 18.9254 121.994 18.225 121.994 17.3928C121.994 16.5605 121.724 15.8601 121.185 15.2906C120.644 14.7211 119.973 14.4368 119.17 14.4368C118.367 14.4368 117.696 14.7211 117.156 15.2906Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M134.214 22.2544V17.1524C134.214 16.306 133.988 15.6379 133.535 15.1488C133.083 14.6598 132.454 14.4152 131.652 14.4152C130.849 14.4152 130.207 14.6672 129.725 15.1704C129.242 15.6736 129.002 16.335 129.002 17.1524V22.2544C129.002 22.3713 128.954 22.4699 128.859 22.5504C128.764 22.6308 128.659 22.6705 128.542 22.6705H126.614C126.498 22.6705 126.396 22.6266 126.308 22.5396C126.22 22.4525 126.177 22.3497 126.177 22.2329V12.5758C126.177 12.4449 126.221 12.3346 126.308 12.2476C126.395 12.1605 126.498 12.1166 126.614 12.1166H128.542C128.659 12.1166 128.764 12.1605 128.859 12.2476C128.954 12.3355 129.002 12.43 129.002 12.5319V13.3642C129.746 12.328 130.914 11.8091 132.506 11.8091C133.878 11.8091 134.976 12.2401 135.801 13.1014C136.626 13.9626 137.038 15.0577 137.038 16.3856V22.2544C137.038 22.3713 136.991 22.4699 136.896 22.5504C136.8 22.6308 136.695 22.6705 136.578 22.6705H134.673C134.556 22.6705 134.45 22.6308 134.356 22.5504C134.261 22.4699 134.214 22.3713 134.214 22.2544Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M148.941 32.6304C148.782 32.6304 148.651 32.5002 148.651 32.3402V0.920496C148.651 0.760513 148.782 0.630371 148.941 0.630371C149.101 0.630371 149.232 0.760513 149.232 0.920496V32.3394C149.232 32.5002 149.101 32.6304 148.941 32.6304Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M169.155 8.27863C169.155 8.50409 169.105 8.70055 169.007 8.86965L162.719 22.0579C162.649 22.1847 162.543 22.2477 162.402 22.2477H161.347C161.249 22.2477 161.175 22.2055 161.126 22.1209C161.076 22.0364 161.073 21.9518 161.115 21.8673L167.53 8.82738H159.575C159.476 8.82738 159.392 8.79256 159.322 8.72376C159.252 8.65496 159.217 8.57124 159.217 8.47425V7.83017C159.217 7.73319 159.252 7.6503 159.322 7.58067C159.393 7.51104 159.477 7.47705 159.575 7.47705H168.796C169.035 7.47705 169.155 7.58232 169.155 7.7937V8.27863Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M180.001 22.2482H170.78C170.681 22.2482 170.597 22.2133 170.527 22.1429C170.456 22.0724 170.422 21.9879 170.422 21.8901V21.3206C170.422 21.0959 170.513 20.9061 170.696 20.7511L175.233 16.531C176.358 15.4758 177.181 14.5723 177.701 13.8196C178.222 13.0669 178.482 12.2969 178.482 11.5094C178.482 10.5951 178.172 9.86311 177.554 9.31519C176.935 8.76644 176.161 8.49206 175.233 8.49206C174.276 8.49206 173.489 8.79131 172.869 9.38897C172.25 9.98662 171.836 10.7153 171.624 11.5732C171.554 11.855 171.378 11.9603 171.097 11.8898L170.506 11.763C170.295 11.7207 170.204 11.5666 170.232 11.2988C170.456 10.1035 171.027 9.12205 171.941 8.35529C172.855 7.58853 173.959 7.20557 175.254 7.20557C176.633 7.20557 177.758 7.59931 178.631 8.38596C179.503 9.17262 179.939 10.1905 179.939 11.4406C179.939 12.3259 179.688 13.1996 179.189 14.0641C178.689 14.9279 177.765 15.9781 176.415 17.2141L172.363 20.9011H180.002C180.099 20.9011 180.181 20.9368 180.244 21.0081C180.307 21.0794 180.34 21.1656 180.34 21.265V21.8867C180.34 21.987 180.308 22.0724 180.244 22.1437C180.181 22.2125 180.099 22.2482 180.001 22.2482Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M181.414 10.1562V9.58668C181.414 9.3753 181.527 9.27003 181.751 9.27003H182.046C183.284 9.27003 184.044 8.7851 184.325 7.81443C184.41 7.58979 184.557 7.47705 184.769 7.47705H185.148C185.373 7.47705 185.486 7.58979 185.486 7.81443V21.8888C185.486 21.9875 185.45 22.072 185.38 22.1416C185.31 22.2121 185.225 22.2469 185.127 22.2469H184.431C184.332 22.2469 184.248 22.2121 184.178 22.1416C184.108 22.0712 184.073 21.9866 184.073 21.8888V10.4521H181.752C181.527 10.4521 181.414 10.3534 181.414 10.1562Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M201.925 14.7932V15.4373C201.925 15.5342 201.889 15.6171 201.82 15.6868C201.749 15.7556 201.665 15.7904 201.567 15.7904H195.701V21.8888C195.701 21.9875 195.665 22.072 195.595 22.1416C195.525 22.2121 195.44 22.2469 195.343 22.2469H194.541C194.442 22.2469 194.358 22.2121 194.288 22.1416C194.218 22.0712 194.183 21.9866 194.183 21.8888V7.83515C194.183 7.73651 194.218 7.65278 194.288 7.58232C194.359 7.51187 194.443 7.47705 194.541 7.47705H203.677C203.776 7.47705 203.86 7.51269 203.93 7.58232C204.001 7.65278 204.036 7.73733 204.036 7.83515V8.51073C204.036 8.60937 204.001 8.69392 203.93 8.76355C203.86 8.83401 203.775 8.86882 203.677 8.86882H195.701V14.4392H201.568C201.805 14.4401 201.925 14.5578 201.925 14.7932Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M207.073 12.8161V18.0409C207.073 19.0563 207.337 19.8496 207.865 20.4208C208.392 20.9919 209.148 21.277 210.134 21.277C211.104 21.277 211.878 20.9778 212.455 20.3785C213.032 19.7792 213.32 19 213.32 18.0409V12.8161C213.32 12.7175 213.355 12.633 213.425 12.5625C213.496 12.492 213.58 12.4564 213.678 12.4564H214.417C214.515 12.4564 214.599 12.492 214.67 12.5617C214.74 12.6321 214.775 12.7167 214.775 12.8145V21.8888C214.775 21.9874 214.739 22.072 214.67 22.1416C214.599 22.2121 214.515 22.2469 214.417 22.2469H213.678C213.58 22.2469 213.495 22.2154 213.425 22.1516C213.355 22.0886 213.32 22.0073 213.32 21.9087V20.5368C213.025 21.1419 212.567 21.6235 211.948 21.9825C211.329 22.3414 210.605 22.5204 209.775 22.5204C208.494 22.5204 207.482 22.1333 206.736 21.3599C205.99 20.5865 205.618 19.5595 205.618 18.2788V12.8137C205.618 12.715 205.652 12.6313 205.723 12.5608C205.793 12.4904 205.87 12.4556 205.955 12.4556H206.714C206.813 12.4556 206.897 12.4912 206.967 12.5617C207.038 12.633 207.073 12.7175 207.073 12.8161Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M225.389 21.9095V16.8033C225.389 15.7763 225.121 14.9714 224.587 14.387C224.053 13.8034 223.286 13.5117 222.287 13.5117C221.302 13.5117 220.511 13.8175 219.913 14.4293C219.314 15.041 219.016 15.8327 219.016 16.8033V21.9095C219.016 22.0082 218.98 22.0886 218.911 22.1524C218.84 22.2154 218.763 22.2478 218.679 22.2478H217.919C217.821 22.2478 217.736 22.2129 217.667 22.1425C217.596 22.072 217.561 21.9875 217.561 21.8897V12.8154C217.561 12.7167 217.596 12.633 217.667 12.5625C217.737 12.4921 217.822 12.4573 217.919 12.4573H218.679C218.763 12.4573 218.84 12.4929 218.911 12.5625C218.981 12.633 219.016 12.7176 219.016 12.8154V14.1657C219.311 13.5747 219.779 13.1005 220.419 12.7416C221.059 12.3827 221.795 12.2036 222.624 12.2036C223.918 12.2036 224.945 12.594 225.706 13.3749C226.465 14.1557 226.845 15.1861 226.845 16.466V21.9104C226.845 22.009 226.81 22.0894 226.74 22.1533C226.67 22.2163 226.585 22.2486 226.487 22.2486H225.749C225.65 22.2486 225.565 22.2171 225.496 22.1533C225.424 22.0894 225.389 22.0082 225.389 21.9095Z" fill={fillStyle || '#0C1D3F'}/>
<path d="M233.766 12.2036C234.596 12.2036 235.341 12.3967 236.002 12.7839C236.663 13.171 237.149 13.6808 237.458 14.3141V7.83515C237.458 7.73651 237.493 7.65278 237.563 7.58232C237.634 7.51187 237.718 7.47705 237.816 7.47705H238.575C238.674 7.47705 238.754 7.51269 238.818 7.58232C238.881 7.65278 238.913 7.73733 238.913 7.83515V21.8888C238.913 21.9875 238.882 22.072 238.818 22.1416C238.755 22.2121 238.674 22.2469 238.575 22.2469H237.816C237.717 22.2469 237.633 22.2121 237.563 22.1416C237.493 22.0712 237.458 21.9866 237.458 21.8888V20.3909C237.134 21.0242 236.646 21.5374 235.992 21.9311C235.337 22.3248 234.602 22.5221 233.787 22.5221C232.408 22.5221 231.24 22.0364 230.283 21.0665C229.327 20.0958 228.849 18.8649 228.849 17.3736C228.849 15.8824 229.327 14.6481 230.283 13.67C231.241 12.6927 232.401 12.2036 233.766 12.2036ZM231.34 14.6514C230.678 15.3834 230.348 16.2902 230.348 17.3736C230.348 18.4571 230.682 19.3606 231.35 20.0851C232.018 20.8096 232.873 21.1718 233.914 21.1718C234.955 21.1718 235.81 20.8096 236.478 20.0851C237.146 19.3606 237.48 18.4571 237.48 17.3736C237.48 16.2902 237.146 15.3792 236.478 14.6407C235.81 13.9021 234.955 13.5332 233.914 13.5332C232.872 13.5324 232.014 13.9054 231.34 14.6514Z" fill={fillStyle || '#0C1D3F'}/>
</svg>
)

  return (
    <SvgIcon component={cleanedPaths} inheritViewBox sx={styles} {...otherProps} />
  )
}

export default Om721Logo
